import React, { useRef } from "react";
import { Link } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";

import Layout from "../../components/mschool/layout";
import SEO from "../../components/seo";
import Card from "../../components/cardmodel";
import SchoolCard from "../../components/schoollicensecardmodel";
import SchoolCardForm from "../../components/school/schoolcardform";
import TopBanner from "../../components/mschool/topbanner";
import Tabs from "../../components/tabs/Tabs";
import TabLink from "../../components/tabs/TabLink";
import TabContent from "../../components/tabs/TabContent";
import Power from "../../components/mschool/power";
import ProInstructors from "../../components/mschool/proinstructors";
import useWindowSize from "../../hooks/useWindowSize";
import SchedulePopup from "../../components/schedulepopup";
import settings from "../../../settings";
// const bannerMobile = `${settings.IMAGES_BASE_URL}/v2/images/mschool/mobile/advanced-m-school.jpg`;
const banner = `${settings.IMAGES_BASE_URL}/v2/images/advanced-m/advanced-m-school-banner.jpg`;
const bannerMobile = `${settings.IMAGES_BASE_URL}/v2/images/advanced-m/advanced-m-school-banner.jpg`;

const virginiatrack = `${settings.IMAGES_BASE_URL}/v2/images/mschool/vir-track.jpg`;
const thermalca = `${settings.IMAGES_BASE_URL}/v2/images/mschool/thermal-track.jpg`;
const motorspeedway = `${settings.IMAGES_BASE_URL}/v2/images/mschool/indy-motor-speedway.png`;

const mdriver = `${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/m-drivers-class.jpg`;
const onedaymschool = `${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/one-day-m-class.jpg`;
const m4gt4school = `${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/m4-gt4-class.jpg`;
const twodaymschool = `${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/two-day-m-class.jpg`;
const racelicense = `${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/race-license-class.jpg`;
const indyfullday = `${settings.IMAGES_BASE_URL}/v2/images/indy_images/full-day-indy-box.jpg`;

const drifting = `${settings.IMAGES_BASE_URL}/v2/images/advanced-m/drifting.jpg`;
const fullLap = `${settings.IMAGES_BASE_URL}/v2/images/advanced-m/full-lap.jpg`;
const leadFollow = `${settings.IMAGES_BASE_URL}/v2/images/advanced-m/lead-follow.jpg`;
const brakes = `${settings.IMAGES_BASE_URL}/v2/images/advanced-m/brakes.jpg`;

const thermal = `${settings.IMAGES_BASE_URL}/v2/images/advanced-m/california-location-wide.jpg`;

const Agenda = () => {
	return (
		<SchedulePopup
			btnText="View Advanced M School Agenda"
			title="ADVANCED M SCHOOL Sample"
			subtitle="Sample schedule subject to change."
		>
			<div className="two_days_schedule">
				<div className="mr_5">
					<h6>DAY ONE</h6>
					<div className="schedule__item">
						<p className="schedule__item__time">7:30 am - 8:30 am</p>
						<p className="schedule__item__event">Arrival and registration</p>
					</div>
					<div className="schedule__item">
						<p className="schedule__item__time">8:30 am - 9:30 am</p>
						<p className="schedule__item__event">Class discussion</p>
					</div>

					<div className="schedule__item">
						<p className="schedule__item__time">9:30 am - 12:20 pm</p>
						<p className="schedule__item__event">Drive time</p>
					</div>

					<div className="schedule__item">
						<p className="schedule__item__time">12:20 pm - 1:10 pm</p>
						<p className="schedule__item__event">Lunch</p>
					</div>
					<div className="schedule__item">
						<p className="schedule__item__time">1:10 pm - 4:30 pm</p>
						<p className="schedule__item__event">Drive time</p>
					</div>
					<div className="schedule__item">
						<p className="schedule__item__time">4:30 pm - 5:00 pm</p>
						<p className="schedule__item__event">Closing</p>
					</div>
				</div>
				<div>
					<h6>DAY TWO</h6>
					<div className="schedule__item">
						<p className="schedule__item__time">8:00 am</p>
						<p className="schedule__item__event">Arrival</p>
					</div>
					<div className="schedule__item">
						<p className="schedule__item__time">8:30 am</p>
						<p className="schedule__item__event">Class discussion</p>
					</div>

					<div className="schedule__item">
						<p className="schedule__item__time">9:00 am</p>
						<p className="schedule__item__event">Drive time</p>
					</div>

					<div className="schedule__item">
						<p className="schedule__item__time">12:20 pm - 1:20 pm</p>
						<p className="schedule__item__event">Lunch</p>
					</div>
					<div className="schedule__item">
						<p className="schedule__item__time">1:20 pm - 4:30 pm</p>
						<p className="schedule__item__event">Drive time</p>
					</div>
					<div className="schedule__item">
						<p className="schedule__item__time">4:30 pm - 5:00 pm</p>
						<p className="schedule__item__event">Closing</p>
					</div>
				</div>
			</div>
		</SchedulePopup>
	);
};

const AdvancedMSchoolPage = () => {
	const scrollToView = (ref) => {
		ref.current.scrollIntoView({
			behavior: "smooth",
		});
	};
	const { width } = useWindowSize();
	const isMobile = width < 1200;
	const isDesktop = width >= 1200;
	const learnRef = useRef(null);
	const locationsRef = useRef(null);
	const powerRef = useRef(null);
	const instructorsRef = useRef(null);
	const classesRef = useRef(null);
  const schoolCardFormRef = useRef(null);
	return (
		<Layout>
			<SEO
				title="BMW Advanced M School | BMW Performance Driving School"
				description="Test your limits with our most BMW Advanced Driving Course yet, BMW Advanced M School. Push a BMW M car on a closed course with pro instructors over two days in California."
        keywords="bmw advanced m school, bmw m driving school, bmw m performance driving school"
			/>
			<div className="advancedmschoolpage">
				<TopBanner
					image={banner}
					mobileImage={bannerMobile}
					header="BMW Advanced M School"
					subheader="BMW ADVANCED DRIVER TRAINING"
					pricing="FROM $4,695"
					text="You’ve taken M School and had the instructors pat you on the back. Now it’s time for the next challenge: Advanced M School. Here you’ll explore the limits with advanced driver training over two days on high-speed tracks that get your heart rate up, and you closer to becoming a true pro."
					buttonText="Book in California"
					butttonURL="https://pds.eventsbmw.com/?location=Thermal%2C%20CA&brand=M&class=Advanced_M_School"
					buttonTwoText="Book on tour"
          scrollToView={() => {
            scrollToView(schoolCardFormRef);
          }}
					agendaPopup={<Agenda />}
				/>
				<section className="links__tabs">
					<div>
						<ul>
							<li>
								<button
									onClick={() => {
										scrollToView(learnRef);
									}}
								>
									What you’ll learn
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(locationsRef);
									}}
								>
									Locations
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(powerRef);
									}}
								>
									What you’ll drive
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(instructorsRef);
									}}
								>
									Pro instructors
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(classesRef);
									}}
								>
									Other classes
								</button>
							</li>
						</ul>
					</div>
				</section>
				<section className="learn" ref={learnRef}>
					<h2>WHAT YOU’LL LEARN</h2>
					{isMobile && (
						<Accordion atomic={true}>
							<AccordionItem title="Lead-Follow">
								<LazyLoadImage
									effect="blur"
									src={drifting}
									alt="Advanced M School"
								/>
								<div className="accordion-content">
									<h3>Follow the leader</h3>
									<p>
										You go where you look, and here you’ll be looking at our
										lead instructor’s car as they guide you around the proper
										line at ever-increasing speeds.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Full Course Lapping">
								<LazyLoadImage
									effect="blur"
									src={drifting}
									alt="Advanced M School"
								/>
								<div className="accordion-content">
									<h3>Game time</h3>
									<p>
										These classes offer the thrill of full-on race courses, and
										you’ll experience everything they have to offer on day two.
										Remember all your brake points and when to go flat out, and
										you’ll soon be lapping faster than you thought possible.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Breaking Down Brakes">
								<LazyLoadImage
									effect="blur"
									src={drifting}
									alt="Advanced M School"
								/>
								<div className="accordion-content">
									<h3>Know when to Stop</h3>
									<p>
										Going fast around a track means knowing when to go slow.
										We’ll break down each section into manageable challenges so
										you can learn the proper driving line through each.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Drifting">
								<LazyLoadImage
									effect="blur"
									src={drifting}
									alt="Advanced M School"
								/>
								<div className="accordion-content">
									<h3>Slip, then grip</h3>
									<p>
										Take one M car. Add water, subtract grip. Feel the
										difference between under- and oversteer, and learn to
										control the back of the car with throttle. Get it right, and
										you’ll start to glide sideways through our polished concrete
										skidpad with ease.
									</p>
								</div>
							</AccordionItem>
						</Accordion>
					)}
					<div className="container">
						{isDesktop && (
							<Tabs>
								<div className="learn__images">
									<TabContent for="lead-follow" uniqueKey="1">
										<img
											src={leadFollow}
											alt="Advanced M School"
										/>
									</TabContent>
									<TabContent for="full-course" uniqueKey="2">
										<img
											src={fullLap}
											alt="Advanced M School"
										/>
									</TabContent>
									<TabContent for="breaking-down" uniqueKey="3">
										<img
											src={brakes}
											alt="Advanced M School"
										/>
									</TabContent>
									<TabContent for="drifting" uniqueKey="4">
										<img
											src={drifting}
											alt="Advanced M School"
										/>
									</TabContent>
								</div>
								<ul className="learn__menu" role="tablist" aria-owns="drifting full-course lead-follow breaking-down" aria-busy="true">
									<li role="presentation">
										<TabLink role="tab" id="lead-follow" to="lead-follow" uniqueKey="5">Lead-Follow</TabLink>
									</li>
									<li role="presentation">
										<TabLink role="tab" id="full-course" to="full-course" uniqueKey="6">Full Course Lapping</TabLink>
									</li>
									<li role="presentation">
										<TabLink role="tab" id="breaking-down" to="breaking-down" uniqueKey="7">Breaking Down Brakes</TabLink>
									</li>
									<li role="presentation">
										<TabLink role="tab" id="drifting" to="drifting" uniqueKey="8">Drifting</TabLink>
									</li>
								</ul>
								<div className="learn__content">
									<TabContent for="lead-follow" uniqueKey="9">
										<h3>Follow the leader</h3>
										<p>
											You go where you look, and here you’ll be looking at our
											lead instructor’s car as they guide you around the proper
											line at ever-increasing speeds.
										</p>
									</TabContent>
									<TabContent for="full-course" uniqueKey="10">
										<h3>Game time</h3>
										<p>
											These classes offer the thrill of full-on race courses,
											and you’ll experience everything they have to offer on day
											two. Remember all your brake points and when to go flat
											out, and you’ll soon be lapping faster than you thought
											possible.
										</p>
									</TabContent>
									<TabContent for="breaking-down" uniqueKey="11">
										<h3>Know when to Stop</h3>
										<p>
											Going fast around a track means knowing when to go slow.
											We’ll break down each section into manageable challenges
											so you can learn the proper driving line through each.
										</p>
									</TabContent>
									<TabContent for="drifting" uniqueKey="12">
										<h3>Slip, then grip</h3>
										<p>
											Take one M car. Add water, subtract grip. Feel the
											difference between under- and oversteer, and learn to
											control the back of the car with throttle. Get it right,
											and you’ll start to glide sideways through our polished
											concrete skidpad with ease.
										</p>
									</TabContent>
								</div>
							</Tabs>
						)}
					</div>
				</section>
				<section className="track" ref={locationsRef}>
					<div className="container">
						<h2 className="track__header-text">CATHEDRALS OF SPEED</h2>
						<div className="row">
							<div className="power__item">
								<div className="power__item__img">
									<LazyLoadImage
										effect="blur"
										src={virginiatrack}
										alt="Virginia International Raceway, Virginia"
									/>
								</div>
								<h3>Virginia International Raceway, Virginia</h3>
								<p>
									This iconic East Coast track is legendary – surrounded by
									beautiful rolling hills – and will prove a challenge to even
									the most seasoned of drivers.
								</p>
							</div>
							<div className="power__item">
								<div className="power__item__img">
									<LazyLoadImage
										effect="blur"
										src={thermalca}
										alt="Thermal, California"
									/>
								</div>
								<h3>Thermal, California</h3>
								<p>
									Set against the backdrop of a stunning mountain range, The
									Thermal Club is an exclusive track that offers three unique
									layouts, plus our own challenge course to encourage maximum
									thrills.
								</p>
							</div>
							<div className="power__item">
								<div className="power__item__img">
									<LazyLoadImage
										effect="blur"
										src={motorspeedway}
										alt="Indianapolis, Indiana"
									/>
								</div>
								<h3>Indianapolis, Indiana</h3>
								<p>
									Home of so many iconic races, the Indianapolis Motor Speedway
									is the perfect backdrop to hone your driving skills. You’ll
									see some serious speed here.
								</p>
							</div>
						</div>
					</div>
				</section>
				<Power ref={powerRef} />
				<ProInstructors ref={instructorsRef} />
				<section className="accomodation">
					<div className="container">
						<h2>SPEED ISN’T THE ONLY ACCOMMODATION</h2>
						<div className="accomodation__content">
							<div className="row">
								<br />
								<div>
									<LazyLoadImage
										effect="blur"
										src={thermal}
										alt="Thermal, California"
									/>
									<br />
									<h3 style={{ textTransform: "capitalize" }}>
										Thermal, California
									</h3>
									<p>
										Enjoy amazing dining, golf, and recreation options at our
										<br />
										sunny Palm Springs location. Book with one of our partners
										<br />
										for special rates.{" "}
									</p>
									<Link to="/hotelpartners">
										<button className="btn btn-bordered">
											See hotel partner list
										</button>
									</Link>
								</div>
							</div>
							<div className="form__cards">
								<div className="form__card__item">
									<Card
										title="Advanced M School - THERMAL"
										price={"From $4,695"}
										subtitle=" "
										url="https://pds.eventsbmw.com/?location=Thermal%2C%20CA&brand=M&class=Advanced_M_School"
										type="advanced_school"
										text="Participants must call 888-345-4269 for verification of their qualifications. We allow graduates of the One-Day M School, as well as other driving experiences. You can book only through our reservation line, or call by clicking below."
									/>
									<div className="form__card__delimiter_width" />
									<SchoolCard
										title="Add Race license school"
										price="$2,495"
										subtitle=""
										hide_links="false"
										text="Participants must have completed either the Two-Day M School or Advanced M School within 12 months of taking this course. You can only book through our reservation line."
									/>
								</div>
								<div className="form__card__item form__card_right">
									<div className="form__card__delimiter_height"  ref={schoolCardFormRef} />
									<SchoolCardForm
										title="advanced m school on tour"
										subtitle="COMING SOON TO A TRACK NEAR YOU"
										hide_links="false"
										text="Looking to join us? We’ll be traveling to select tracks throughout the U.S., and you can get in on the action by filling out the form below. We’ll contact you when location dates become available."
									/>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="other-schools" ref={classesRef}>
					<div className="container">
						<h2>YOU MAY ALSO LIKE</h2>
					</div>
					<div className="other-schools__content row">
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={onedaymschool}
								alt="One-Day M School"
							/>
							<h3>One-Day M School</h3>
							<p>The basics of performance are anything but basic.</p>
							<Link to="/mschool/onedaymschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={twodaymschool}
								alt="Two-Day M School"
							/>
							<h3>Two-Day M School</h3>
							<p>Focus on the details of true M Performance</p>
							<Link to="/mschool/twodaymschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={racelicense}
								alt="Race License School"
							/>
							<h3>Race License School</h3>
							<p>Feel the need for real speed.</p>
							<Link to="/mschool/racelicenseschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={mdriver}
								alt="M Driver’s Program"
							/>
							<h3>M Driver’s Program</h3>
							<p>The basics of performance are anything but basic.</p>
							<Link to="/mschool/mdriver">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={m4gt4school}
								alt="M4 GT4 School"
							/>
							<h3>M4 GT4 School</h3>
							<p>Learn to drive the ultimate BMW M.</p>
							<Link to="/mschool/m4gt4">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={indyfullday}
								alt="Full-Day M Experience at Indy"
							/>
							<h3>Full-Day M Experience at Indy</h3>
							<p>Full-on thrills on a hallowed track.</p>
							<Link to="/indy-fullday">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default AdvancedMSchoolPage;
